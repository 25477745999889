import React from 'react'
import cx from 'classnames'
import { closeModal, modalVisibility, ModalVisibilityProps, openModal } from 'modal'

import { Modal } from 'components/feedback'
import { ButtonProps } from 'components/inputs'
import { Icon, IconName, Text } from 'components/dataDisplay'

import ColoredButton from '../ColoredButton/ColoredButton'


type InfoModalProps = {
  className?: string
  title: string
  text: string
  bottomNode?: boolean
  icon?: IconName
  primaryButton?: Partial<ButtonProps>
  secondaryButton?: Partial<ButtonProps>
}

const InfoModal: React.FC<InfoModalProps & ModalVisibilityProps> = (props) => {
  const {
    className, title, text, icon, primaryButton, secondaryButton, bottomNode, closeModal,
  } = props

  return (
    <Modal
      className={cx(className, 'flex flex-col justify-center')}
      title=""
      color="warrior"
      closeModal={closeModal}
    >
      <div className="mw-618 margin-auto">
        {
          Boolean(icon) && (
            <Icon className="mb-32px" name={icon} color="lebowski" />
          )
        }
        <Text
          size="h32"
          message={title}
          color="schindler"
        />
        <Text
          className="mt-16px"
          size="t16-24"
          message={text}
          color="schindler"
        />
        {
          Boolean(primaryButton && secondaryButton) && (
            <div className="mt-32px flex">
              <ColoredButton
                className="flex-1"
                title={secondaryButton.title}
                color="jaws"
                onClick={secondaryButton.onClick}
              />
              <ColoredButton
                className="ml-32px flex-1"
                title={primaryButton.title}
                disabled={primaryButton.disabled}
                color="platoon"
                onClick={primaryButton.onClick}
              />
            </div>
          )
        }
        {
          bottomNode && (
            <>
              <span>
                <Text
                  size="t16-24"
                  message="Если вы не получили чек, проверьте правильно ли ввели имейл и обратитесь в поддержку"
                  color="titanic"
                />
                <Icon
                  name="work/support_16"
                  color="rocky"
                />
                <Text
                  size="t16-24"
                  message="8 800 000 00-00"
                  color="titanic"
                />
              </span>
            </>
          )
        }
      </div>
    </Modal>
  )
}


export const openInfoModal = (props: InfoModalProps) => openModal('infoModal', props)

export const closeInfoModal = () => closeModal('infoModal')

export default modalVisibility('infoModal', InfoModal)
